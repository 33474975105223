import React from "react";
import Service from "templates/service";
import Heading from "components/core/Typography/Heading";
import { useTranslation } from "react-i18next";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  PINTEREST_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "constants/social";

export interface TermsOfUseProps {
  location: Location;
}

export default ({ location }: TermsOfUseProps) => {
  const { t } = useTranslation();

  return (
    <Service
      title={t("pages.terms.title")}
      subtitle={t("pages.terms.subtitle")}
      seoProps={{
        title: t("pages.terms.seo.title"),
        description: t("pages.terms.seo.description"),
        image: {
          relativePath: "meta/customerly-live-chat-terms-of-use.jpg",
          alt: "Customerly Terms of Use",
        },
        structuredData: [
          {
            "@context": "https://schema.org",
            "@type": "Corporation",
            brand: "Customerly",
            name: "Live Chat Software",
            sameAs: [
              FACEBOOK_URL,
              INSTAGRAM_URL,
              LINKEDIN_URL,
              PINTEREST_URL,
              TWITTER_URL,
              YOUTUBE_URL,
            ],
            url: "https://www.customerly.io/",
          },
        ],
      }}
      location={location}
    >
      <Heading level={1}>The Agreement</Heading>
      <p>
        Welcome to Customerly! These terms and conditions ("Terms") govern your
        use of our SaaS platform and related services (collectively, the
        "Services"), provided to you by Customerly Limited ("Customerly," "we,"
        "us," "our"), a company registered in Ireland at Ground Floor 71 Lower
        Baggot Street, Dublin, Dublin D02 P593. By accessing or using our
        Services, you agree to be bound by these Terms. If you do not agree to
        these Terms, please do not use our Services.{" "}
      </p>
      <p>
        We operate the website https://www.customerly.io (the "Site"), the
        mobile application Customerly (the "App"), as well as any other related
        products and services that refer or link to these legal terms (the
        "Legal Terms") (collectively, the "Services").{" "}
      </p>
      <p>
        You can contact us via chat on customerly.io, email at
        legal@customerly.io, or by mail to Ground Floor 71 Lower Baggot Street,
        Dublin, Dublin D02P593, Ireland.{" "}
      </p>
      <p>
        These Legal Terms constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity ("you"), and
        Customerly Limited, concerning your access to and use of the Services.
        You agree that by accessing the Services, you have read, understood, and
        agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
        ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
        THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.{" "}
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the
        Services from time to time are hereby expressly incorporated herein by
        reference. We reserve the right, in our sole discretion, to make changes
        or modifications to these Legal Terms from time to time. We will alert
        you about any changes by updating the "Last updated" date of these Legal
        Terms, and you waive any right to receive specific notice of each such
        change. It is your responsibility to periodically review these Legal
        Terms to stay informed of updates. You will be subject to, and will be
        deemed to have been made aware of and to have accepted, the changes in
        any revised Legal Terms by our continued use of the Services after the
        date such revised Legal Terms are posted.
      </p>
      <p>
        The Services are intended for users who are at least 18 years old.
        Persons under the age of 18 are not permitted to use or register for the
        Services.{" "}
      </p>
      <p>
        We recommend that you print a copy of these Legal Terms for your
        records.
      </p>
      <Heading level={2}>1. OUR SERVICES</Heading>
      <p>
        The information provided when using the Services is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who
        choose to access the Services from other locations do so on their own
        initiative and are solely responsible for compliance with local laws, if
        and to the extent local laws are applicable.{" "}
      </p>
      <p>
        The Services are not tailored to comply with industry-specific
        regulations (Health Insurance Portability and Accountability Act
        (HIPAA), Federal Information Security Management Act (FISMA), etc.), so
        if your interactions would be subjected to such laws, you may not use
        the Services. You may not use the Services in a way that would violate
        the Gramm-Leach-Bliley Act (GLBA).
      </p>
      <Heading level={2}>2. INTELLECTUAL PROPERTY RIGHTS</Heading>
      <Heading level={3}>Our intellectual property</Heading>

      <p>
        We are the owner or the licensee of all intellectual property rights in
        our Services, including all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics
        in the Services (collectively, the "Content"), as well as the
        trademarks, service marks, and logos contained therein (the "Marks").
      </p>
      <p>
        Our Content and Marks are protected by copyright and trademark laws (and
        various other intellectual property rights and unfair competition laws)
        and treaties in the United States and around the world.
      </p>
      <p>
        The Content and Marks are provided in or through the Services "AS IS"
        for your internal business purpose only.
      </p>
      <Heading level={3}>Your use of our Services</Heading>
      <p>
        Subject to your compliance with these Legal Terms, including the
        "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive,
        non-transferable, revocable license to:
        <ul>
          <li>access the Services; and</li>
          <li>
            download or print a copy of any portion of the Content to which you
            have properly gained access.
          </li>
        </ul>
        solely for your internal business purpose.
      </p>
      <p>
        Except as set out in this section or elsewhere in our Legal Terms, no
        part of the Services and no Content or Marks may be copied, reproduced,
        aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise
        exploited for any commercial purpose whatsoever, without our express
        prior written permission.
      </p>
      <p>
        If you wish to make any use of the Services, Content, or Marks other
        than as set out in this section or elsewhere in our Legal Terms, please
        address your request to: legal@customerly.io. If we ever grant you the
        permission to post, reproduce, or publicly display any part of our
        Services or Content, you must identify us as the owners or licensors of
        the Services, Content, or Marks and ensure that any copyright or
        proprietary notice appears or is visible on posting, reproducing, or
        displaying our Content.
      </p>
      <p>
        We reserve all rights not expressly granted to you in and to the
        Services, Content, and Marks.
      </p>
      <p>
        Any breach of these Intellectual Property Rights will constitute a
        material breach of our Legal Terms and your right to use our Services
        will terminate immediately.
      </p>
      <Heading level={3}>Your submissions and contributions</Heading>
      <p>
        Please review this section and the "PROHIBITED ACTIVITIES" section
        carefully prior to using our Services to understand the (a) rights you
        give us and (b) obligations you have when you post or upload any content
        through the Services.
      </p>
      <p>
        Submissions: By directly sending us any question, comment, suggestion,
        idea, feedback, or other information about the Services ("Submissions"),
        you agree to assign to us all intellectual property rights in such
        Submission. You agree that we shall own this Submission and be entitled
        to its unrestricted use and dissemination for any lawful purpose,
        commercial or otherwise, without acknowledgment or compensation to you.
      </p>
      <p>
        Contributions: The Services may invite you to chat, contribute to, or
        participate in blogs, message boards, online forums, and other
        functionality during which you may create, submit, post, display,
        transmit, publish, distribute, or broadcast content and materials to us
        or through the Services, including but not limited to text, writings,
        video, audio, photographs, music, graphics, comments, reviews, rating
        suggestions, personal information, or other material ("Contributions").
        Any Submission that is publicly posted shall also be treated as a
        Contribution.
      </p>
      <p>
        You understand that Contributions may be viewable by other users of the
        Services and possibly through third-party websites.
      </p>
      <p>
        When you post Contributions, you grant us a license (including use of
        your name, trademarks, and logos): By posting any Contributions, you
        grant us an unrestricted, unlimited, irrevocable, perpetual,
        non-exclusive, transferable, royalty-free, fully-paid, worldwide right,
        and license to: use, copy, reproduce, distribute, sell, resell, publish,
        broadcast, retitle, store, publicly perform, publicly display, reformat,
        translate, excerpt (in whole or in part), and exploit your Contributions
        (including, without limitation, your image, name, and voice) for any
        purpose, commercial, advertising, or otherwise, to prepare derivative
        works of, or incorporate into other works, your Contributions, and to
        sublicense the licenses granted in this section. Our use and
        distribution may occur in any media formats and through any media
        channels.
      </p>
      <p>
        This license includes our use of your name, company name, and franchise
        name, as applicable, and any of the trademarks, service marks, trade
        names, logos, and personal and commercial images you provide.
      </p>
      <p>
        You are responsible for what you post or upload: By sending us
        Submissions and/or posting Contributions through any part of the
        Services or making Contributions accessible through the Services by
        linking your account through the Services to any of your social
        networking accounts, you:
        <ul>
          <li>
            confirm that you have read and agree with our "PROHIBITED
            ACTIVITIES" and will not post, send, publish, upload, or transmit
            through the Services any Submission nor post any Contribution that
            is illegal, harassing, hateful, harmful, defamatory, obscene,
            bullying, abusive, discriminatory, threatening to any person or
            group, sexually explicit, false, inaccurate, deceitful, or
            misleading;
          </li>
          <li>
            to the extent permissible by applicable law, waive any and all moral
            rights to any such Submission and/or Contribution;
          </li>
          <li>
            warrant that any such Submission and/or Contributions are original
            to you or that you have the necessary rights and licenses to submit
            such Submissions and/or Contributions and that you have full
            authority to grant us the above-mentioned rights in relation to your
            Submissions and/or Contributions; and
          </li>
          <li>
            warrant and represent that your Submissions and/or Contributions do
            not constitute confidential information.
          </li>
        </ul>
        You are solely responsible for your Submissions and/or Contributions and
        you expressly agree to reimburse us for any and all losses that we may
        suffer because of your breach of (a) this section, (b) any third party’s
        intellectual property rights, or (c) applicable law.
      </p>
      <p>
        We may remove or edit your Content: Although we have no obligation to
        monitor any Contributions, we shall have the right to remove or edit any
        Contributions at any time without notice if in our reasonable opinion we
        consider such Contributions harmful or in breach of these Legal Terms.
        If we remove or edit any such Contributions, we may also suspend or
        disable your account and report you to the authorities.
      </p>
      <Heading level={3}>Copyright infringement</Heading>
      <p>
        We respect the intellectual property rights of others. If you believe
        that any material available on or through the Services infringes upon
        any copyright you own or control, please immediately refer to the
        "COPYRIGHT INFRINGEMENTS" section below.
      </p>
      <Heading level={2}>3. USER REPRESENTATIONS</Heading>
      <p>
        By using the Services, you represent and warrant that: (1) all
        registration information you submit will be true, accurate, current, and
        complete; (2) you will maintain the accuracy of such information and
        promptly update such registration information as necessary; (3) you have
        the legal capacity and you agree to comply with these Legal Terms; (4)
        you are not a minor in the jurisdiction in which you reside; (5) you
        will not access the Services through automated or non-human means,
        whether through a bot, script or otherwise; (6) you will not use the
        Services for any illegal or unauthorized purpose; and (7) your use of
        the Services will not violate any applicable law or regulation.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Services (or any
        portion thereof).
      </p>
      <Heading level={2}>4. USER REGISTRATION</Heading>
      <p>
        You may be required to register to use the Services. You agree to keep
        your password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>
      <Heading level={2}>5. USE OF THE APPLICATION SERVICES</Heading>

      <Heading level={3}>5.1 Plan Selection</Heading>
      <p>
        Subscriber may choose from one of the following plans: Essential,
        Startup, Pro, or Enterprise. Each plan is tailored with specific
        features, number of teammates, and interactions, based on the size of
        the company.
      </p>
      <Heading level={3}>5.2 Subscription Plans</Heading>
      <p>
        Startup, Pro, and Enterprise plans may be customized by adding
        additional teammates and interactions, based on Subscriber's needs.
        Additional teammates and interactions will incur an additional cost, as
        outlined in the Service pricing.
      </p>
      <p>
        The Application Services are provided through existing Subscription
        Plans. For all Subscription Plans: an “Interaction” shall mean any of
        the following :
        <ul>
          <li>
            A new unique conversation received to which your team replied to
          </li>
          <li>
            Any individual email message sent to a customer via the Services
            like:
            <ul>
              <li>reply via email to a conversation</li>
              <li>a conversation transcript sent</li>
              <li>Workflows emails and chat messages</li>
              <li>Outbound Email sending</li>
              <li>NPS Survey</li>
              <li>CSAT Survey</li>
              <li>Chatflow Notification Response</li>
            </ul>
          </li>
          <li>Any individual In-app Survey sent</li>
        </ul>
      </p>

      <p>
        We reserve the right to introduce new types of interactions and modify
        the definitions of existing interactions without prior notification.
        Such changes may include, but are not limited to, the addition of new
        communication channels, automated interactions, and other engagement
        metrics. These new interaction types will be subject to the same billing
        and usage terms as the current interactions unless otherwise specified.
      </p>

      <p>
        Additionally, Customerly reserves the right to add, remove, or modify
        the available Subscription Plans at any time. This may include the
        introduction of new plans, discontinuation of existing plans, or changes
        to the features and pricing of any plan. Subscribers will be notified of
        any significant changes to their existing plans; however, we are not
        obligated to provide prior notice for minor adjustments or the
        introduction of new plans.
      </p>

      <p>
        By continuing to use the Services, you agree to comply with any such
        changes or additions to the interaction types and Subscription Plans. If
        you do not agree with the changes, you must notify us and discontinue
        use of the Services prior to the changes taking effect.
      </p>

      <Heading level={3}>5.3 Free Services</Heading>
      <p>Customerly does not offer free Subscription Plans at no cost.</p>
      <Heading level={3}>5.4 Pricing</Heading>

      <p>
        Customerly reserves the right, in its sole discretion, to modify the
        pricing of its services and Subscription Plans, add new services or
        pricing plans for additional fees and charges, or amend fees and charges
        for existing services, at any time without prior notice to Customer.
        Notwithstanding the foregoing, and except with respect to the On-demand
        Charges described in Section 5.8 , unless otherwise provided in an Order
        Form, the fees set forth on an Order Form shall remain fixed for the
        duration of the Subscription Term set forth therein.
      </p>

      <Heading level={3}>5.5 Payments</Heading>

      <p>
        We accept payments exclusively via Stripe, and we currently support the
        following forms of payment:
      </p>
      <ul>
        <li>Visa</li>
        <li>MasterCard</li>
        <li>American Express</li>
      </ul>
      <p>
        For tailored yearly Enterprise plans, we also accept wire transfers.
        However, customers opting for wire transfers must maintain a valid
        payment method on file to cover any additional charges incurred due to
        extra interactions or AI usage. This ensures that any overages or
        unplanned usage fees are promptly settled.
      </p>
      <p>
        You agree to provide current, complete, and accurate purchase and
        account information for all purchases made via the Services. You further
        agree to promptly update account and payment information, including
        email address, payment method, and payment card expiration date, so that
        we can complete your transactions and contact you as needed. Sales tax
        will be added to the price of purchases as deemed required by us. We
        accept payments in EUR and USD, and we may support additional currencies
        in the future to better accommodate our customers. We may change prices
        at any time.
      </p>
      <p>
        You agree to pay all charges at the prices then in effect for your
        purchases and any applicable fees, and you authorize us to charge your
        chosen payment provider for any such amounts upon placing your order. If
        your order is subject to recurring charges, then you consent to our
        charging your payment method on a recurring basis without requiring your
        prior approval for each recurring charge, until such time as you cancel
        the applicable order. We reserve the right to correct any errors or
        mistakes in pricing, even if we have already requested or received
        payment.
      </p>
      <p>
        We reserve the right to refuse any order placed through the Services. We
        may, in our sole discretion, limit or cancel quantities purchased per
        person, per household, or per order. These restrictions may include
        orders placed by or under the same customer account, the same payment
        method, and/or orders that use the same billing address. We reserve the
        right to limit or prohibit orders that, in our sole judgment, appear to
        be placed by dealers, resellers, or distributors.
      </p>
      <p>
        By maintaining a valid payment method on file, you ensure that your
        account remains in good standing and that any additional charges due to
        exceeding usage limits are promptly covered. Failure to maintain
        accurate and current payment information may result in the suspension or
        termination of your account and access to the Services.
      </p>
      <Heading level={3}>5.6 Subscription Term</Heading>
      <p>
        Subscription Plans are billed in advance of the Subscription Term and
        automatically renew for an equivalent renewal term at the end of the
        Subscription Term, unless otherwise stated in an Order Form. Customer
        may opt not to renew for an equivalent renewal term by writing to
        Customerly at https://www.customerly.io/chat-with-us or contacting their
        Customerly representative. Fees paid hereunder, including On-demand
        interactions, are non-cancellable during a Term, are non-refundable and
        shall be billed in U.S. Dollars or Euros. Except as otherwise set forth
        in an Order Form, fees due hereunder will be billed to Customer’s credit
        card. Customer authorizes: (i) the card issuer to pay all such amounts
        and (ii) Customerly (or its billing agent) to charge the credit card
        account until the Application Services are terminated as set forth
        herein; provided, however, that if payment is not received from the
        credit card issuer, Customer agrees to pay all amounts due upon demand.
        Customer understands and agrees that it must provide current, complete,
        and accurate billing and payment information. Additionally, Customer
        agrees to pay all costs of collection, including attorney’s fees and
        costs, on any outstanding balance. In certain instances, there may be
        banking or financial institution transaction fees or related charges,
        which Customer understands and agrees it shall be responsible to pay.
      </p>
      <p>
        Subscriber may select either a monthly or yearly subscription term.
        Billing will begin on the next billing date after the 14-day free trial
        period ends. Monthly subscriptions will be charged on the same day each
        month, while yearly subscriptions will be charged once per year, after
        365 days from the initial billing date.
      </p>
      <p>
        To interrupt a subscription, the Customer must delete the related
        Customerly project. This must be done by the Customer within the project
        settings. We do not delete projects on request because we do not take
        responsibility for deleting all business data without an authentication
        method. The Customer must follow the proper procedures within the
        platform to ensure the secure and verified deletion of their project and
        associated data.
      </p>
      <p>
        Failure to delete the project properly will result in the continuation
        of billing and renewal of the subscription term. Customers are
        encouraged to ensure they have followed all necessary steps to delete
        their project to avoid any unwanted charges.
      </p>
      <Heading level={3}>5.7 Taxes</Heading>
      <p>
        Fees do not include any local, state, federal or foreign taxes, levies,
        duties or similar governmental assessments of any nature, including
        value-added, use or withholding taxes (collectively, “Taxes”). Customer
        is responsible for payment of all Taxes associated with its purchases
        hereunder (excluding taxes based on Customerly’s net income or
        property), and any related penalties and interest. Customer will make
        all required payments to Customerly free and clear of, and without
        reduction for, any withholding taxes. Customer will, upon Customerly’s
        request, provide Customerly with official receipts issued by appropriate
        taxing authorities, or such other evidence as Customerly may reasonably
        request, to establish that such Taxes have been paid.
      </p>
      <Heading level={3}>5.8 Free trial</Heading>
      <p>
        Subscriber may sign up for a 14-day free trial before selecting a plan.
        The billing date will start at the end of the free trial period. If
        Subscriber does not cancel the subscription before the end of the free
        trial period, Subscriber will be automatically charged for the selected
        plan.
      </p>
      <Heading level={3}>5.9 On-demand Charges</Heading>
      <p>
        In the event Customer’s use of the Application Services exceeds the
        included interactions tier of the Subscription Plan purchased by
        Customer, Customerly will charge overage fees (“On-demand Charges”)
        consistent with the Documentation, at (i) +20% of the unit price
        applicable to Customer’s selected included interactions tier for our
        Subscription Plans and Customer shall be obligated to pay any On-demand
        Charges at the end of the billing period. Notwithstanding (i) and (ii),
        a different unit price shall be applicable to Customer’s selected
        included interactions tier if such unit price is agreed between Customer
        and Customerly during the Subscription Plan purchasing process.
      </p>

      <Heading level={3}>5.10 Cancellation</Heading>
      <p>
        All purchases are non-refundable. You can cancel your subscription at
        any time by logging into your account. Your cancellation will take
        effect at the end of the current paid term. If you are unsatisfied with
        our Services, please chat with us.
      </p>

      <p>
        To cancel your subscription, you must delete the related Customerly
        project. This process must be completed by the Customer within the
        project settings. We do not delete projects on request because we do not
        take responsibility for deleting all business data without an
        authentication method. The Customer is responsible for following the
        proper procedures within the platform to ensure the secure and verified
        deletion of their project and associated data.
      </p>

      <p>
        Failure to delete the project properly will result in the continuation
        of billing and renewal of the subscription term. Customers are
        encouraged to ensure they have followed all necessary steps to delete
        their project to avoid any unwanted charges. If you do not delete your
        project, the subscription will remain active, and charges will continue
        to be incurred.
      </p>

      <Heading level={3}>5.11 Credits</Heading>

      <p>
        Customerly may, at its sole discretion, choose to offer credits for the
        Application Services, including discount codes or promotional campaigns.
        Credits are non-transferable and do not have any monetary or cash value.
        Any credits can only be used to offset fees per the terms and conditions
        expressly identified by Customerly and, unless otherwise stated by
        Customerly, shall no longer be redeemable ninety (90) days from the date
        of issuance.
      </p>

      <Heading level={3}>5.12 Failure of Payment</Heading>
      <p>
        If Customer fails to pay fees within five (14) business days of notice
        of delinquent payments, in addition to other available remedies,
        Customerly reserves the right to suspend or terminate access to the
        Application Services and delete the Customer Content (as defined below).
      </p>
      <Heading level={3}>5.13 Courtesy Limit</Heading>
      <p>
        As a gesture of goodwill, we offer a courtesy limit of 100.000 contacts
        for Essential, Startup and Pro plans. This means that users on
        Essential, Startup and Pro plans will not be charged for the first
        100.000 contacts they upload or store in our system. However, any
        contacts beyond this limit will be subject to additional pricing.
      </p>

      <Heading level={2}>6. THIRD-PARTY SERVICES</Heading>
      <Heading level={3}>6.1 Amazon Web Services (AWS) </Heading>
      <p>
        We use Amazon Web Services (AWS) for hosting and infrastructure
        services. While we strive to ensure these services are reliable, we do
        not guarantee their uninterrupted availability and are not liable for
        any disservice caused by AWS.
      </p>

      <Heading level={3}>6.2 Azure OpenAI and Pinecone Services </Heading>
      <p>
        We use Azure OpenAI services within the EU region and Pinecone services
        within the EU region. While we strive to ensure these services are
        reliable, we do not guarantee their uninterrupted availability and are
        not liable for any disservice caused by these third-party providers.
      </p>

      <Heading level={3}>6.3 Other Third-Party Services </Heading>
      <p>
        In addition to AWS, Azure OpenAI, and Pinecone, we also utilize services
        from:
        <ul>
          <li>Stripe: For payment processing.</li>
          <li>OneSignal: For push notifications.</li>
          <li>Apple: For iOS application distribution and related services.</li>
          <li>
            Google Play: For Android application distribution and related
            services.
          </li>
        </ul>
        While we strive to ensure that these services are reliable, we do not
        guarantee their uninterrupted availability and are not liable for any
        disservice caused by these third-party providers.
      </p>

      <Heading level={2}>7. LIMITATION OF LIABILITY</Heading>

      <Heading level={3}>7.1 Third-Party Disservice</Heading>
      <p>
        We are not responsible for any economic loss or damages caused by a
        disservice of any third party, including but not limited to AWS, Azure,
        Pinecone, Stripe, OneSignal, Apple, and Google Play. The availability
        and functionality of our Services depend on these third-party services,
        and any disruption or unavailability of these services may impact the
        performance of our Services.
      </p>

      <Heading level={3}>7.2 Internal Issues</Heading>
      <p>
        We are not responsible for any economic loss or damages caused by
        internal issues within Customerly. While we strive to maintain the
        reliability and functionality of our Services, we do not guarantee their
        uninterrupted availability and are not liable for any disruptions or
        issues arising from internal problems.
      </p>

      <Heading level={3}>7.3 AI-Generated Responses</Heading>
      <p>
        We do not respond for any misinformation or errors shared by the AI
        chatbot. The AI capabilities integrated into our Services are designed
        to provide assistance and information to the best of their programmed
        abilities. However, we cannot guarantee the accuracy, completeness, or
        reliability of the information provided by the AI chatbot. Customers
        should verify the information provided and use it at their own
        discretion. We shall not be held liable for any actions taken based on
        misinformation or errors shared by the AI chatbot.
      </p>

      <Heading level={3}>7.4 Data Security</Heading>
      <p>
        We are not responsible for any misconfiguration of the product which
        might result in sensitive data of users being shared with other users.
        The customer is responsible for adopting the best security practices in
        place to avoid such occurrences.
      </p>

      <Heading level={3}>7.5 Feature Expectations</Heading>
      <p>
        We are not responsible for any missing features the customer is
        expecting to have on Customerly. Features and functionalities of the
        Services may change over time, and customers should not rely on the
        availability of specific features. By using our Services, you
        acknowledge and agree to these limitations of liability.
      </p>

      <Heading level={3}>7.6 Economic Damage Due to Misconfiguration</Heading>
      <p>
        We are not responsible for any economic damage caused by the
        misconfiguration of the Services by the customer. It is the customer’s
        responsibility to ensure that the Services are configured correctly
        according to their needs and to adopt best practices for security and
        functionality.
      </p>

      <Heading level={2}>8. SOFTWARE</Heading>
      <p>
        We may include software for use in connection with our Services. If such
        software is accompanied by an end user license agreement ("EULA"), the
        terms of the EULA will govern your use of the software. If such software
        is not accompanied by a EULA, then we grant to you a non-exclusive,
        revocable, personal, and non-transferable license to use such software
        solely in connection with our services and in accordance with these
        Legal Terms. Any software and any related documentation is provided "AS
        IS" without warranty of any kind, either express or implied, including,
        without limitation, the implied warranties of merchantability, fitness
        for a particular purpose, or non-infringement. You accept any and all
        risk arising out of use or performance of any software. You may not
        reproduce or redistribute any software except in accordance with the
        EULA or these Legal Terms.
      </p>
      <Heading level={2}>9. PROHIBITED ACTIVITIES</Heading>
      <p>
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. The Services may not be used
        in connection with any commercial endeavors except those that are
        specifically endorsed or approved by us.
      </p>
      <p>As a user of the Services, you agree not to:</p>
      <ul>
        <li>
          Systematically retrieve data or other content from the Services to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Services, including features that prevent or restrict
          the use or copying of any Content or enforce limitations on the use of
          the Services and/or the Content contained therein.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Services.
        </li>
        <li>
          Use any information obtained from the Services in order to harass,
          abuse, or harm another person.
        </li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>
          Use the Services in a manner inconsistent with any applicable laws or
          regulations.
        </li>
        <li>Engage in unauthorized framing of or linking to the Services.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the
          Services or modifies, impairs, disrupts, alters, or interferes with
          the use, features, functions, operation, or maintenance of the
          Services.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li>
          Delete the copyright or other proprietary rights notice from any
          Content.
        </li>
        <li>
          Attempt to impersonate another user or person or use the username of
          another user.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as "spyware" or "passive
          collection mechanisms" or "pcms").
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Services or
          the networks or services connected to the Services.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Services to you.
        </li>
        <li>
          Attempt to bypass any measures of the Services designed to prevent or
          restrict access to the Services, or any portion of the Services.
        </li>
        <li>
          Copy or adapt the Services' software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code.
        </li>
        <li>
          Except as permitted by applicable law, decipher, decompile,
          disassemble, or reverse engineer any of the software comprising or in
          any way making up a part of the Services.
        </li>
        <li>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Services, or use
          or launch any unauthorized script or other software.
        </li>
        <li>
          Use a buying agent or purchasing agent to make purchases on the
          Services.
        </li>
        <li>
          Make any unauthorized use of the Services, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretenses.
        </li>
        <li>
          Use the Services as part of any effort to compete with us or otherwise
          use the Services and/or the Content for any revenue-generating
          endeavor or commercial enterprise.
        </li>
        <li>Sell or otherwise transfer your profile.</li>
      </ul>
      <Heading level={2}>10. USER GENERATED CONTRIBUTIONS</Heading>
      <p>
        The Services may invite you to chat, contribute to, or participate in
        blogs, message boards, online forums, and other functionality, and may
        provide you with the opportunity to create, submit, post, display,
        transmit, perform, publish, distribute, or broadcast content and
        materials to us or on the Services, including but not limited to text,
        writings, video, audio, photographs, graphics, comments, suggestions, or
        personal information or other material (collectively, "Contributions").
        Contributions may be viewable by other users of the Services and through
        third-party websites. As such, any Contributions you transmit may be
        treated as non-confidential and non-proprietary. When you create or make
        available any Contributions, you thereby represent and warrant that:
        <ul>
          <li>
            The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li>
            You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Services, and other users of the Services to use your
            Contributions in any manner contemplated by the Services and these
            Legal Terms.
          </li>
          <li>
            You have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Services and these Legal Terms.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </li>
          <li>
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li>
            Your Contributions are not used to harass or threaten (in the legal
            sense of those terms) any other person and to promote violence
            against a specific person or class of people.
          </li>
          <li>
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </li>
          <li>
            Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li>
            Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Legal Terms, or any applicable
            law or regulation.
          </li>
        </ul>
        Any use of the Services in violation of the foregoing violates these
        Legal Terms and may result in, among other things, termination or
        suspension of your rights to use the Services.
      </p>
      <Heading level={2}>11. CONTRIBUTION LICENSE</Heading>
      <p>
        By posting your Contributions to any part of the Services, you
        automatically grant, and you represent and warrant that you have the
        right to grant, to us an unrestricted, unlimited, irrevocable,
        perpetual, non-exclusive, transferable, royalty-free, fully-paid,
        worldwide right, and license to host, use, copy, reproduce, disclose,
        sell, resell, publish, broadcast, retitle, archive, store, cache,
        publicly perform, publicly display, reformat, translate, transmit,
        excerpt (in whole or in part), and distribute such Contributions
        (including, without limitation, your image and voice) for any purpose,
        commercial, advertising, or otherwise, and to prepare derivative works
        of, or incorporate into other works, such Contributions, and grant and
        authorize sublicenses of the foregoing. The use and distribution may
        occur in any media formats and through any media channels.
      </p>
      <p>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.
      </p>
      <p>
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Services. You are
        solely responsible for your Contributions to the Services and you
        expressly agree to exonerate us from any and all responsibility and to
        refrain from any legal action against us regarding your Contributions.
      </p>
      <p>
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any Contributions; (2) to re-categorize any
        Contributions to place them in more appropriate locations on the
        Services; and (3) to pre-screen or delete any Contributions at any time
        and for any reason, without notice. We have no obligation to monitor
        your Contributions.
      </p>
      <Heading level={2}>12. MOBILE APPLICATION LICENSE</Heading>
      <Heading level={3}>Use License</Heading>
      <p>
        If you access the Services via the App, then we grant you a revocable,
        non-exclusive, non-transferable, limited right to install and use the
        App on wireless electronic devices owned or controlled by you, and to
        access and use the App on such devices strictly in accordance with the
        terms and conditions of this mobile application license contained in
        these Legal Terms. You shall not: (1) except as permitted by applicable
        law, decompile, reverse engineer, disassemble, attempt to derive the
        source code of, or decrypt the App; (2) make any modification,
        adaptation, improvement, enhancement, translation, or derivative work
        from the App; (3) violate any applicable laws, rules, or regulations in
        connection with your access or use of the App; (4) remove, alter, or
        obscure any proprietary notice (including any notice of copyright or
        trademark) posted by us or the licensors of the App; (5) use the App for
        any revenue-generating endeavor, commercial enterprise, or other purpose
        for which it is not designed or intended; (6) make the App available
        over a network or other environment permitting access or use by multiple
        devices or users at the same time; (7) use the App for creating a
        product, service, or software that is, directly or indirectly,
        competitive with or in any way a substitute for the App; (8) use the App
        to send automated queries to any website or to send any unsolicited
        commercial email; or (9) use any proprietary information or any of our
        interfaces or our other intellectual property in the design,
        development, manufacture, licensing, or distribution of any
        applications, accessories, or devices for use with the App.
      </p>
      <Heading level={3}>Apple and Android Devices</Heading>
      <p>
        The following terms apply when you use the App obtained from either the
        Apple Store or Google Play (each an "App Distributor") to access the
        Services: (1) the license granted to you for our App is limited to a
        non-transferable license to use the application on a device that
        utilizes the Apple iOS or Android operating systems, as applicable, and
        in accordance with the usage rules set forth in the applicable App
        Distributor’s terms of service; (2) we are responsible for providing any
        maintenance and support services with respect to the App as specified in
        the terms and conditions of this mobile application license contained in
        these Legal Terms or as otherwise required under applicable law, and you
        acknowledge that each App Distributor has no obligation whatsoever to
        furnish any maintenance and support services with respect to the App;
        (3) in the event of any failure of the App to conform to any applicable
        warranty, you may notify the applicable App Distributor, and the App
        Distributor, in accordance with its terms and policies, may refund the
        purchase price, if any, paid for the App, and to the maximum extent
        permitted by applicable law, the App Distributor will have no other
        warranty obligation whatsoever with respect to the App; (4) you
        represent and warrant that (i) you are not located in a country that is
        subject to a US government embargo, or that has been designated by the
        US government as a "terrorist supporting" country and (ii) you are not
        listed on any US government list of prohibited or restricted parties;
        (5) you must comply with applicable third-party terms of agreement when
        using the App, e.g., if you have a VoIP application, then you must not
        be in violation of their wireless data service agreement when using the
        App; and (6) you acknowledge and agree that the App Distributors are
        third-party beneficiaries of the terms and conditions in this mobile
        application license contained in these Legal Terms, and that each App
        Distributor will have the right (and will be deemed to have accepted the
        right) to enforce the terms and conditions in this mobile application
        license contained in these Legal Terms against you as a third-party
        beneficiary thereof.
      </p>
      <Heading level={2}>13. THIRD-PARTY WEBSITES AND CONTENT</Heading>
      <p>
        The Services may contain (or you may be sent via the Site or App) links
        to other websites ("Third-Party Websites") as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software, and other content or items
        belonging to or originating from third parties ("Third-Party Content").
        Such Third-Party Websites and Third-Party Content are not investigated,
        monitored, or checked for accuracy, appropriateness, or completeness by
        us, and we are not responsible for any Third-Party Websites accessed
        through the Services or any Third-Party Content posted on, available
        through, or installed from the Services, including the content,
        accuracy, offensiveness, opinions, reliability, privacy practices, or
        other policies of or contained in the Third-Party Websites or the
        Third-Party Content. Inclusion of, linking to, or permitting the use or
        installation of any Third-Party Websites or any Third-Party Content does
        not imply approval or endorsement thereof by us. If you decide to leave
        the Services and access the Third-Party Websites or to use or install
        any Third-Party Content, you do so at your own risk, and you should be
        aware these Legal Terms no longer govern. You should review the
        applicable terms and policies, including privacy and data gathering
        practices, of any website to which you navigate from the Services or
        relating to any applications you use or install from the Services. Any
        purchases you make through Third-Party Websites will be through other
        websites and from other companies, and we take no responsibility
        whatsoever in relation to such purchases which are exclusively between
        you and the applicable third party. You agree and acknowledge that we do
        not endorse the products or services offered on Third-Party Websites and
        you shall hold us blameless from any harm caused by your purchase of
        such products or services. Additionally, you shall hold us blameless
        from any losses sustained by you or harm caused to you relating to or
        resulting in any way from any Third-Party Content or any contact with
        Third-Party Websites.
      </p>
      <Heading level={2}>14. SERVICES MANAGEMENT</Heading>
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the
        Services for violations of these Legal Terms; (2) take appropriate legal
        action against anyone who, in our sole discretion, violates the law or
        these Legal Terms, including without limitation, reporting such user to
        law enforcement authorities; (3) in our sole discretion and without
        limitation, refuse, restrict access to, limit the availability of, or
        disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in our sole discretion and
        without limitation, notice, or liability, to remove from the Services or
        otherwise disable all files and content that are excessive in size or
        are in any way burdensome to our systems; and (5) otherwise manage the
        Services in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Services.
      </p>
      <Heading level={2}>15. PRIVACY POLICY</Heading>
      <p>
        We care about data privacy and security. Please review our Privacy
        Policy: https://www.customerly.io/privacy/. By using the Services, you
        agree to be bound by our Privacy Policy, which is incorporated into
        these Legal Terms. Please be advised the Services are hosted in Ireland.
        If you access the Services from any other region of the world with laws
        or other requirements governing personal data collection, use, or
        disclosure that differ from applicable laws in Ireland, then through
        your continued use of the Services, you are transferring your data to
        Ireland, and you expressly consent to have your data transferred to and
        processed in Ireland.
      </p>
      <Heading level={2}>16. COPYRIGHT INFRINGEMENTS</Heading>
      <p>
        We respect the intellectual property rights of others. If you believe
        that any material available on or through the Services infringes upon
        any copyright you own or control, please immediately notify us using the
        contact information provided below (a "Notification"). A copy of your
        Notification will be sent to the person who posted or stored the
        material addressed in the Notification. Please be advised that pursuant
        to applicable law you may be held liable for damages if you make
        material misrepresentations in a Notification. Thus, if you are not sure
        that material located on or linked to by the Services infringes your
        copyright, you should consider first contacting an attorney.
      </p>
      <Heading level={2}>17. TERM AND TERMINATION</Heading>
      <p>
        These Legal Terms shall remain in full force and effect while you use
        the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
        WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
        OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES
        OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
        ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </p>
      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </p>
      <Heading level={2}>18. MODIFICATIONS AND INTERRUPTIONS</Heading>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Services at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Services. We will not be liable to you or any third party for any
        modification, price change, suspension, or discontinuance of the
        Services.
      </p>
      <p>
        We cannot guarantee the Services will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Services, resulting in interruptions, delays,
        or errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Services at any time or for any
        reason without notice to you. You agree that we have no liability
        whatsoever for any loss, damage, or inconvenience caused by your
        inability to access or use the Services during any downtime or
        discontinuance of the Services. Nothing in these Legal Terms will be
        construed to obligate us to maintain and support the Services or to
        supply any corrections, updates, or releases in connection therewith.
      </p>
      <Heading level={2}>19. GOVERNING LAW</Heading>
      <p>
        These Legal Terms are governed by and interpreted following the laws of
        Ireland, and the use of the United Nations Convention of Contracts for
        the International Sales of Goods is expressly excluded. If your habitual
        residence is in the EU, and you are a consumer, you additionally possess
        the protection provided to you by obligatory provisions of the law in
        your country to residence. Customerly Limited and yourself both agree to
        submit to the non-exclusive jurisdiction of the courts of Dublin, which
        means that you may make a claim to defend your consumer protection
        rights in regards to these Legal Terms in Ireland, or in the EU country
        in which you reside.
      </p>
      <Heading level={2}>20. DISPUTE RESOLUTION</Heading>
      <Heading level={3}>Informal Negotiations</Heading>
      <p>
        To expedite resolution and control the cost of any dispute, controversy,
        or claim related to these Legal Terms (each a "Dispute" and
        collectively, the "Disputes") brought by either you or us (individually,
        a "Party" and collectively, the "Parties"), the Parties agree to first
        attempt to negotiate any Dispute (except those Disputes expressly
        provided below) informally for at least thirty (30) days before
        initiating arbitration. Such informal negotiations commence upon written
        notice from one Party to the other Party.
      </p>
      <Heading level={3}>Binding Arbitration</Heading>
      <p>
        Any dispute arising from the relationships between the Parties to these
        Legal Terms shall be determined by one arbitrator who will be chosen in
        accordance with the Arbitration and Internal Rules of the European Court
        of Arbitration being part of the European Centre of Arbitration having
        its seat in Strasbourg, and which are in force at the time the
        application for arbitration is filed, and of which adoption of this
        clause constitutes acceptance. The seat of arbitration shall be Dublin,
        Ireland. The language of the proceedings shall be English. Applicable
        rules of substantive law shall be the law of Ireland.
      </p>

      <Heading level={3}>Restrictions</Heading>
      <p>
        The Parties agree that any arbitration shall be limited to the Dispute
        between the Parties individually. To the full extent permitted by law,
        (a) no arbitration shall be joined with any other proceeding; (b) there
        is no right or authority for any Dispute to be arbitrated on a
        class-action basis or to utilize class action procedures; and (c) there
        is no right or authority for any Dispute to be brought in a purported
        representative capacity on behalf of the general public or any other
        persons.
      </p>

      <Heading level={3}>
        Exceptions to Informal Negotiations and Arbitration
      </Heading>
      <p>
        The Parties agree that the following Disputes are not subject to the
        above provisions concerning informal negotiations binding arbitration:
        (a) any Disputes seeking to enforce or protect, or concerning the
        validity of, any of the intellectual property rights of a Party; (b) any
        Dispute related to, or arising from, allegations of theft, piracy,
        invasion of privacy, or unauthorized use; and (c) any claim for
        injunctive relief. If this provision is found to be illegal or
        unenforceable, then neither Party will elect to arbitrate any Dispute
        falling within that portion of this provision found to be illegal or
        unenforceable and such Dispute shall be decided by a court of competent
        jurisdiction within the courts listed for jurisdiction above, and the
        Parties agree to submit to the personal jurisdiction of that court.
      </p>

      <Heading level={2}>21. CORRECTIONS</Heading>
      <p>
        There may be information on the Services that contains typographical
        errors, inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies, or omissions and to change or update
        the information on the Services at any time, without prior notice.
      </p>
      <Heading level={2}>22. DISCLAIMER</Heading>
      <p>
        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
        THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
        EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
        IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES'
        CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
        THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
        (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
        PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
        FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS
        TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
        AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
        CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
        VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
        THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
        OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
        KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED,
        OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
        ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </p>
      <Heading level={2}>23. LIMITATIONS OF LIABILITY</Heading>
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
        USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
        OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
        OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,
        BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF
        ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
        ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
        OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
        DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
        ADDITIONAL RIGHTS.
      </p>
      <Heading level={2}>24. INDEMNIFICATION</Heading>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) your
        Contributions; (2) use of the Services; (3) breach of these Legal Terms;
        (4) any breach of your representations and warranties set forth in these
        Legal Terms; (5) your violation of the rights of a third party,
        including but not limited to intellectual property rights; or (6) any
        overt harmful act toward any other user of the Services with whom you
        connected via the Services. Notwithstanding the foregoing, we reserve
        the right, at your expense, to assume the exclusive defense and control
        of any matter for which you are required to indemnify us, and you agree
        to cooperate, at your expense, with our defense of such claims. We will
        use reasonable efforts to notify you of any such claim, action, or
        proceeding which is subject to this indemnification upon becoming aware
        of it.
      </p>
      <Heading level={2}>25. USER DATA</Heading>
      <p>
        We will maintain certain data that you transmit to the Services for the
        purpose of managing the performance of the Services, as well as data
        relating to your use of the Services. Although we perform regular
        routine backups of data, you are solely responsible for all data that
        you transmit or that relates to any activity you have undertaken using
        the Services. You agree that we shall have no liability to you for any
        loss or corruption of any such data, and you hereby waive any right of
        action against us arising from any such loss or corruption of such data.
      </p>
      <Heading level={2}>
        26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
      </Heading>
      <p>
        Visiting the Services, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Services, satisfy any legal requirement that such communication
        be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SERVICES. You hereby waive any rights or requirements
        under any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>
      <Heading level={2}>28. MISCELLANEOUS</Heading>
      <p>
        These Legal Terms and any policies or operating rules posted by us on
        the Services or in respect to the Services constitute the entire
        agreement and understanding between you and us. Our failure to exercise
        or enforce any right or provision of these Legal Terms shall not operate
        as a waiver of such right or provision. These Legal Terms operate to the
        fullest extent permissible by law. We may assign any or all of our
        rights and obligations to others at any time. We shall not be
        responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or
        part of a provision of these Legal Terms is determined to be unlawful,
        void, or unenforceable, that provision or part of the provision is
        deemed severable from these Legal Terms and does not affect the validity
        and enforceability of any remaining provisions. There is no joint
        venture, partnership, employment or agency relationship created between
        you and us as a result of these Legal Terms or use of the Services. You
        agree that these Legal Terms will not be construed against us by virtue
        of having drafted them. You hereby waive any and all defenses you may
        have based on the electronic form of these Legal Terms and the lack of
        signing by the parties hereto to execute these Legal Terms.
      </p>
      <Heading level={2}>29. CONTACT US</Heading>
      <p>
        In order to resolve a complaint regarding the Services or to receive
        further information regarding use of the Services, please contact us at:
      </p>
      <p>Customerly Limited</p>
      <p>Ground Floor 71 Lower Baggot Street</p>
      <p>Dublin, Dublin D02 P593</p>
      <p>legal@customerly.io</p>
    </Service>
  );
};
